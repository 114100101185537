/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:43 AM */

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.eot');
	src: local('Avenir Next Cyr Bold Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Light.eot');
	src: local('Avenir Next Cyr Light'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Light'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Light.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot');
	src: local('Avenir Next Cyr Demi'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Demi.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.eot');
	src: local('Avenir Next Cyr Thin Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Italic.eot');
	src: local('Avenir Next Cyr Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Italic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Italic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.eot');
	src: local('Avenir Next Cyr Heavy Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.eot');
	src: local('Avenir Next Cyr Medium Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.eot');
	src: local('Avenir Next Cyr Light Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-LightItalic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot');
	src: local('Avenir Next Cyr Medium'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr Ultra';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.eot');
	src: local('Avenir Next Cyr Ultra Light Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot');
	src: local('Avenir Next Cyr Regular'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr Ultra';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot');
	src: local('Avenir Next Cyr Ultra Light'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-UltraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot');
	src: local('Avenir Next Cyr Heavy'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot');
	src: local('Avenir Next Cyr Bold'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.eot');
	src: local('Avenir Next Cyr Demi Italic'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-DemiItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
    font-display: swap;
	font-family: 'Avenir Next Cyr';
	src: url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot');
	src: local('Avenir Next Cyr Thin'), local('/public/fonts/AvenirNextCyr/AvenirNextCyr-Thin'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Thin.woff') format('woff'),
		url('/public/fonts/AvenirNextCyr/AvenirNextCyr-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}
